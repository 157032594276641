.suggestionList {
    margin-top: 0.25rem;
    padding: 0;
    /*border: 1px solid #ccc;*/
    border-top-color: #d9d9d9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    color:black;
    cursor: pointer;
  
  }

  .suggestionListItem {
    padding: 0.35rem;
    list-style-type: none;
    border: 1px solid #6e6c82;
    cursor: pointer;
  }

  @media (max-width: 992px){
    
    .sms-left-side:focus-within {
        height: 100vh;
    }

    .sms-left-side:focus-within .adress-btn-submit {
      display: none;
    }
    .sms-left-side:focus-within .fieldName {
      display: none;
    }

    .suggestionList {
      overflow: auto;
      /* max-height: 250px; */
      max-height: 50vh;
    }

  }

/* .sms-left-side:focus-within .suggestionList {
  display: none;
} */

