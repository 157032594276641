.topMenu {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    padding: 30px 60px;
    justify-content: space-between;
}

.switchbuttons {
    font-size: 16px !important;
}

.tableTop{
    display: flex;
    justify-content: flex-end;
    padding: 5px 50px;
}