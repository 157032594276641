.geocodeEditDetailDataTableHeader {
  padding: 7px !important;
  background: #f9f9f9 !important;
}

.geocodeEditDetailInput {
  width: 100%;
}

.geocodeEditDetailData {
    background: #7892aa;
    padding: 5px;
    color: white;
    height: 33px;
    width: 95%;
    border-radius: 2px
};

 
  