
/*
 DataTable css ================================================
*/
.datatable-crud-demo{
  width: 100%;
  margin: auto;
  /* background: #ad9f9f; */
  /* padding: 14px; */
   padding: 5px; 
}
.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
    



/*
 Data Table -> İndex.css ================================================
*/
 

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }
p {
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

.card {
  margin-bottom: 2rem;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
      background-color: rgba(165, 165, 165, 0.1)
  }
  50% {
      background-color: rgba(165, 165, 165, 0.3)
  }
  100% {
      background-color: rgba(165, 165, 165, 0.1)
  }
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.customer-badge.status-qualified {
  background-color: #C8E6C9;
  color: #256029;
}

.customer-badge.status-unqualified {
  background-color: #FFCDD2;
  color: #C63737;
}

.customer-badge.status-negotiation {
  background-color: #FEEDAF;
  color: #8A5340;
}

.customer-badge.status-new {
  background-color: #B3E5FC;
  color: #23547B;
}

.customer-badge.status-renewal {
  background-color: #ECCFFF;
  color: #694382;
}

.customer-badge.status-proposal {
  background-color: #FFD8B2;
  color: #805B36;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.product-badge.status-instock {
  background: #C8E6C9;
  color: #256029;
}

.product-badge.status-outofstock {
  background: #FFCDD2;
  color: #C63737;
}

.product-badge.status-lowstock {
  background: #FEEDAF;
  color: #8A5340;
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.order-badge.order-delivered {
  background: #C8E6C9;
  color: #256029;
}

.order-badge.order-cancelled {
  background: #FFCDD2;
  color: #C63737;
}

.order-badge.order-pending {
  background: #FEEDAF;
  color: #8A5340;
}

.order-badge.order-returned {
  background: #ECCFFF;
  color: #694382;
}

.image-text {
  vertical-align: middle;
  margin-left: .5rem;
}

.p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-representative-option img {
  vertical-align: middle;
  width: 24px;
}

.p-multiselect-representative-option span {
  margin-top: .125rem;
}

.p-column-filter {
  width: 100%;
}

.country-item {
  display: flex;
  align-items: center;
}

.country-item img.flag {
  width: 18px;
  margin-right: .5rem;
}

.flag {
  vertical-align: middle;
}

span.flag {
  width:44px;
  height:30px;
  display:inline-block;
}

img.flag {
  width:30px
}


.redDot {
  height: 25px;
  width: 25px;
  background-color: rgb(243, 10, 10);
  border-radius: 50%;
  display: inline-block;
}

.blueDot {
  height: 25px;
  width: 25px;
  background-color: rgb(21, 61, 241);
  border-radius: 50%;
  display: inline-block;
}

.greenDot {
  height: 25px;
  width: 25px;
  background-color: rgb(83, 216, 6);
  border-radius: 50%;
  display: inline-block;
}

.grayDot {
  height: 25px;
  width: 25px;
  background-color: rgb(173, 173, 173);
  border-radius: 50%;
  display: inline-block;
}



.yellowDot {
  height: 25px;
  width: 25px;
  background-color: rgb(240, 220, 3);
  border-radius: 50%;
  display: inline-block;
}

.p-datatable-thead tr {
  background: #f8f9fa;
}

@media only screen and (max-width: 1440px) {
  .dataTablecontainer{
    font-size: 12px;
  } 
}

@media only screen and (max-width: 960px) {
  .dataTablecontainer{
    font-size: 10px;
  } 
}


.paymentContent {
  margin-top: 15px;
}

.pc-w-250 {
  width: 250px;
}

.pc-w-50 {
  width: 50px;
}

.pc-w-40 {
  width: 40px;
}

.pc-w-100 {
  width: 100px;
}


.pc-w-110 {
  width: 110px;
}


.pc-ml-15 {
  margin-left: 15px;
}

.pc-mr-10 {
  margin-right: 10px;
}

.countPriceContent {
  display: flex;
  justify-content: flex-start;
}

.opHeight {
  height: 32px;
}

.pc-remove {
  background: red;
  text-align: center;
  padding-top: 6px;
  color: white;
  margin-right: 5px;
  border-radius: 4px;
}


.pc-header {
  display: flex;
  justify-content: flex-start;
  height: 30px;
  background-color: beige;
  align-items: center;
  margin-top: 15px;
}


.pc-header span:nth-child(1) {
  margin-right: 11px;
  margin-left: 2px;
}

.pc-header span:nth-child(2) {
  margin-right: 67px;
}

.pc-header span:nth-child(3) {
  margin-right: 80px;
}

.pc-header span:nth-child(4) {
  margin-right: 7px;
}

.pc-span-fontw {
  font-weight: 500;
}


/* .p-datatable .p-datatable-thead > tr > th {
  padding: 0px;
} */


