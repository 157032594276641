.errorMain {
  display: flex;
  background-color: white;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.errorOops {
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
  font-size: 102px;
  display: flex;
  margin: 5px;
}
.errorPNF {
  display: flex;
  margin-top: 50px;
  font-weight: bold;
  font-size: 25px;
}
.homePage {
  margin-top: 20px;
  background-color: #f9423a;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  -webkit-box-shadow: 4px 3px 16px -2px #000000; 
  box-shadow: 4px 3px 16px -2px #000000;
}
.homePage {
  font-size: 20px;
  color: white;
}
