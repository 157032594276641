.wrapper {
  position: sticky;
  top: 0;
  z-index: 9;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
  font-family: "Public Sans", sans-serif;
  margin-bottom: 40px;
  &.mobile {
    padding: 0 15px;
    height: 60px;
    margin-bottom: 20px;
    justify-content: space-between;
    .logo {
      width: 144px;
    }
  }

  figure {
    margin: 0;
  }

  .logo {
    width: 100px;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  nav {
    padding: 0 10px;

    > ul {
      display: flex;
      align-items: center;
      > li {
        position: relative;
        height: 80px;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          height: 2px;
          background-color: #212b36;
          transition: 0.4s ease;
          opacity: 0;
          transform: scale(0);
          z-index: 2;
        }
        &:hover,
        &.active {
          &:after {
            opacity: 1;
            transform: scale(1);
          }
          a {
            color: #212b36;
          }
        }
        &:hover {
          ul {
            opacity: 1;
            z-index: 2;
            visibility: visible;
            transform: translateY(0);
          }
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
        > a {
          line-height: 80px;
        }
      }
      ul {
        position: absolute;
        left: 0;
        top: 100%;
        padding: 4px;
        border-radius: 0 0 4px 4px;
        background-color: #ffffff;
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
        transition: 0.4s ease;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        transform: translateY(10px);
        li {
          transition: 0.4s ease;
          &:hover {
            opacity: 0.7;
          }
          &:not(:last-child) {
            margin-bottom: 6px;
          }
          a {
            padding: 4px 0;
          }
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: #637381;
      white-space: nowrap;
      transition: 0.4s ease;
    }
  }

  .search {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #f6f7f9;
    border-radius: 4px;
    padding: 10px;
    font-size: 12px;
    color: #63738130;
    cursor: pointer;
    transition: 0.4s ease;
    &:hover {
      color: #637381;
    }
    svg {
      font-size: 16px;
    }
    span {
      padding-left: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: 1024px) {
    padding: 0 10px;
  }
  @media (min-width: 1200px) {
    padding: 0 30px;
    //padding: 0 3px 0 30px;
    .logo {
      width: 144px;
    }
    nav {
      padding: 0 30px;
      > ul > li {
        &:not(:last-child) {
          margin-right: 20px;
        }
        ul {
          padding: 10px;
        }
      }
    }
    .search {
      margin-right: 10px;
    }
  }
  @media (min-width: 1366px) {
    nav {
      margin-left: 30px;
      a {
        font-size: 14px;
      }
    }
    .search {
      flex: 0.7;
      font-size: 14px;
      margin: 0 40px;
      svg {
        font-size: 20px;
      }
    }
  }
}
