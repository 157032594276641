.fakeDiv{
    height: 100;
    width: 100;
    background-color:transparent;
}

.navbarcontainer {
    font-family:"Mark Pro Medium" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}

 .active {
    color:black !important;
    font-weight: 600 !important;
} 

.heyBringoHeaderLogo {
    width: 120px !important;
}