.addPopupDataTableHeader {
  padding: 2px !important;
  background: #f9f9f9 !important;
}
  
.addPopupInput {
  width: 100%;
}
  
.geocodeEditDetailData {
  background: #7892aa;
  padding: 5px;
  color: white;
  height: 33px;
  width: 95%;
  border-radius: 2px
};

.addPopupTableCell{
  padding: 2px !important;
}
   
    