.p-fileupload .p-fileupload-buttonbar button:not(.p-fileupload-choose) {  
    display: none;  
}

.p-fileupload .p-fileupload-choose {
    margin-left: 30%;
}
  

.visitDelayP{
    background: #ff6262;
    padding: 5px;
    width: 55px;
    color: white;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    float: left;
    margin-left: 10px;
    float: left;
}

.visitDCa {
 float: left;
 width: 150px;
}