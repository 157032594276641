/* g[id="turkiye"] > g[data-is-election-city="false"] > path {
  fill: gray !important;
}

g[id="turkiye"] > g[data-is-election-city="false"] > path:hover {
  fill: darkgray !important;
} */

g[id="turkiye"] > g[data-is-election-city="true"] > path {
  fill: #229a16 !important;
}

g[id="turkiye"] > g[data-is-election-city="true"] > path:hover {
  fill: #229a16 !important;
}
