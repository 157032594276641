/* DialogDemo.css */

.dialog-demo .p-button {
    margin: 0 .5rem 0 0;
    min-width: 10rem;
}

.dialog-demo p {
    margin: 0;
    line-height: 1.5;
}

.dialog-demo .p-dialog .p-button {
    min-width: 6rem;
}
          
.w100{
    width: 100%;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: none;
}

.upd{
    background: greenyellow !important;
}