.filter{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.customerText{
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: bold;
}

.dropdownCustomer{
    margin-right: 10px;
}

/* .WOtable .p-datatable-wrapper table .p-datatable-thead {
    display: none;
} */
.rating{
   justify-content: center;
    display: flex;
}
.ratingNumber{
    width: 30px;
}

.iconUp{
    color: #4bb543;
}
.iconDown{
    color: #FA113D;
}
.links{
    margin: 10px 20px;
    font-size: 16px;
}
.links span{
    margin: 0px 5px ;
}
.links:hover{
    cursor: pointer;
}

.subtable .p-datatable-wrapper table .p-datatable-thead {
    display: none;
}

.rigthIcons{
    position: relative;
    top: -10px;
}