 body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
     sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }

 code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
     monospace;
 }

 @font-face {
   font-family: "Mark Pro Medium";
   src: url("./fonts/a05747eaf46bae50f19dfa950b86befd.eot");
   /* IE9*/
   src: url("./fonts/a05747eaf46bae50f19dfa950b86befd.eot?#iefix") format("embedded-opentype"),
     /* IE6-IE8 */
     url("./fonts/a05747eaf46bae50f19dfa950b86befd.woff2") format("woff2"),
     /* chrome、firefox */
     url("./fonts/a05747eaf46bae50f19dfa950b86befd.woff") format("woff"),
     /* chrome、firefox */
     url("./fonts/a05747eaf46bae50f19dfa950b86befd.ttf") format("truetype"),
     /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
     url("./fonts/a05747eaf46bae50f19dfa950b86befd.svg#Mark Pro Medium") format("svg");
   /* iOS 4.1- */
 }

 @font-face {
   font-family: "MarkPro-Regular";
   src: url("./fonts/c7e919b8358daafbec26cde70a5237f9.eot");
   /* IE9*/
   src: url("./fonts/c7e919b8358daafbec26cde70a5237f9.eot?#iefix") format("embedded-opentype"),
     /* IE6-IE8 */
     url("./fonts/c7e919b8358daafbec26cde70a5237f9.woff2") format("woff2"),
     /* chrome、firefox */
     url("./fonts/c7e919b8358daafbec26cde70a5237f9.woff") format("woff"),
     /* chrome、firefox */
     url("./fonts/c7e919b8358daafbec26cde70a5237f9.ttf") format("truetype"),
     /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
     url("./fonts/c7e919b8358daafbec26cde70a5237f9.svg#MarkPro-Regular") format("svg");
   /* iOS 4.1- */
 }

 @font-face {
   font-family: "Mark Pro Heavy";
   src: url("./fonts/72112afab388987941068c3f7a741a53.eot");
   /* IE9*/
   src: url("./fonts/72112afab388987941068c3f7a741a53.eot?#iefix") format("embedded-opentype"),
     /* IE6-IE8 */
     url("./fonts/72112afab388987941068c3f7a741a53.woff2") format("woff2"),
     /* chrome、firefox */
     url("./fonts/72112afab388987941068c3f7a741a53.woff") format("woff"),
     /* chrome、firefox */
     url("./fonts/72112afab388987941068c3f7a741a53.ttf") format("truetype"),
     /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
     url("./fonts/72112afab388987941068c3f7a741a53.svg#Mark Pro Heavy") format("svg");
   /* iOS 4.1- */
 }