/* popup scroll hide */
.ui .segment {
overflow: hidden;
}

.vehicleLeftElementWidth {
    width: 200px;
}

.p-dialog-content > div > div > a {
    padding: 7px !important;
}